export const JR_NINJA = {
  title: 'Jr. Tricks & Tumbling',
  gender: 'Boys & Girls',
  ages: 'Ages 8–9',
  description:
    'This class is for boys and girls ages 8–9 and runs for 45 minutes. Each class can be tailored to beginner and advanced skill levels. Athletes will work on our specialized Shinobi equipment designed for parkour training as well as the various apparatus throughout the gym including Floor, Vault, Rings and Bars. This class provides experienced instruction for children who are interested in indoor and outdoor parkour training. Class is held strictly indoors.',
  cat1: 'Jr. Boys Ninja',
}

export const SR_NINJA = {
  title: 'Sr. Tricks & Tumbling',
  gender: 'Boys & Girls',
  ages: 'Ages 10–17',
  description:
    'This class is for boys and girls ages 10–17 and runs for 45 minutes. Each class can be tailored to beginner and advanced skill levels. Athletes will work on our specialized Shinobi equipment designed for parkour training as well as the various apparatus throughout the gym including Floor, Vault, Rings and Bars. This class provides experienced instruction for athletes who are interested in indoor and outdoor parkour training. Class is held strictly indoors.',
  cat1: 'Sr. Boys Gym',
}
