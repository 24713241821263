<template>
  <v-sheet class="py-12" dark>
    <v-container
      class="pa-6"
      style="border-radius: 6px"
      :style="{
        border: `2px solid ${this.$vuetify.theme.themes.light.secondary.base}`,
      }"
    >
      <p class="text-h4 font-fancy">Join the mailing list</p>
      <div class="text-h6 mt-6">Be the first to know</div>
      <div class="mt-2">
        <ul>
          <li>When class schedules are posted</li>
          <li>When registration begins</li>
          <li>When policies change and require your action</li>
        </ul>
      </div>

      <!-- Signup form -->
      <v-form ref="form" v-model="valid" class="mt-10">
        <v-text-field
          v-model.trim="name"
          label="Your name"
          color="secondary"
          :rules="nameRules"
          outlined
          data-testid="mailing-list-name-input"
          @keyup.enter="submit"
        />
        <v-text-field
          v-model.trim="email"
          label="Your email"
          color="secondary"
          :rules="emailRules"
          outlined
          data-testid="mailing-list-email-input"
          @keyup.enter="submit"
        />
      </v-form>
      <v-btn
        class="secondary"
        x-large
        block
        :loading="loading"
        data-testid="mailing-list-submit-button"
        @click="submit"
      >
        Join mailing list
      </v-btn>

      <!-- Snackbar -->
      <!-- TODO Move snackbar into singular store service -->
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ snackbarMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbarDismiss"> Dismiss </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      email: '',
      name: '',
      loading: false,
      snackbar: false,
      snackbarColor: null,
      snackbarMessage: '',
    }
  },
  computed: {
    emailRules() {
      return [
        v => !!v || 'Email is required',
        v => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(v) || 'Must be a valid email'
        },
      ]
    },
    nameRules() {
      return [v => !!v || 'Name is required', v => v.length <= 100 || 'Too long']
    },
  },
  methods: {
    async submit() {
      if (!this.valid) return this.$refs.form.validate()
      this.loading = true
      try {
        await this.$http.post('/join-mailing-list/', { email: this.email, name: this.name })
        this.snackbarColor = 'success'
        this.snackbarMessage = 'Joined mailing list'
        this.snackbar = true
      } catch (error) {
        const detail = error.response.data.detail
        if (detail.includes('The contact must re-subscribe to get back on the list')) {
          this.snackbarColor = 'warning'
          this.snackbarMessage =
            'You were already on our mailing list then unsubscribed. To rejoin, find one of our old emails and click "Update your preferences" at the bottom.'
          this.snackbar = true
        } else if (detail.includes('is already a list member')) {
          this.snackbarColor = 'success'
          this.snackbarMessage = "You're already on our mailing list"
          this.snackbar = true
        } else {
          this.snackbarColor = 'error'
          this.snackbarMessage = detail
          this.snackbar = true
        }
      } finally {
        this.loading = false
      }
    },
    snackbarDismiss() {
      this.snackbar = false
    },
  },
}
</script>

<style scoped>
.v-sheet {
  background-image: url('../assets/black-tile.jpg');
  background-repeat: repeat;
}
</style>
