<template>
  <span class="font-weight-bold">{{ formattedName }}</span>
</template>

<script>
export default {
  props: {
    name: String,
  },
  computed: {
    formattedName() {
      const SUFFIX_REGEX = /\s\d:\d\d\s\(.*\)/ // A space, a digit, a colon, then two digits, a space, some characters inside parentheses
      const NOTHING = ''
      const name = this.name.replace(SUFFIX_REGEX, NOTHING)
      return name
    },
  },
}
</script>
