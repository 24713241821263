export const JR_CIRCUS = {
  title: 'Jr. Circus',
  gender: 'Boys & Girls',
  ages: 'Ages 6–8',
  description:
    'This program incorporates instruction in both Ground Tricks and Aerial Arts. Beginner participants ages 6–8 will receive introductory instruction in acrobatics, static trapeze, aerial hoop and silks. This class will run once a week for 1 hour.',
  cat1: 'Beginner Circus',
}

export const SR_CIRCUS = {
  title: 'Sr. Circus',
  gender: 'Boys & Girls',
  ages: 'Ages 9–12',
  description:
    'Similar to the beginner program, this class incorporates instruction on both Ground Tricks and Aerial Arts. Older participants will receive more advanced instruction in acrobatics, static trapeze, aerial hoop and silks. This class will run once a week for 1 hour 25 minutes.',
  cat1: 'Advanced Circus',
}

export const TEEN_CIRCUS = {
  title: 'Teen Circus',
  gender: 'Boys & Girls',
  ages: 'Ages 13–17',
  description:
    'This class is for athletes 13+. The Teen Circus Arts class will provide instruction on static trapeze, aerial hoop and aerials silks only. This class will run once a week for 1 hour 25 minutes.',
  cat1: 'Teen Circus',
}

export const ADULT_CIRCUS = {
  title: 'Adult Circus',
  gender: 'Men & Women',
  ages: 'Ages 18+',
  description:
    'This class for adults 18+ who are interested in participating in Aerial Arts. Each participant will receive instruction on the Aerial Silks, Hoop, and Static Trapeze. Focusing on one apparatus is also an option. This class will run once a week for 1 hour 25 minutes.',
  cat1: 'Adult Circus',
}
