import { TEST_CLASS_1, TEST_CLASS_2, TEST_CLASS_3 } from '@/assets/classTypes/test'

import ACRO from '@/assets/disciplines/acro'
import CIRCUS from '@/assets/disciplines/circus'
import GYMNASTICS from '@/assets/disciplines/gymnastics'
import NINJA from '@/assets/disciplines/ninja'

const TEST_CLASSES = [TEST_CLASS_1, TEST_CLASS_2, TEST_CLASS_3]

export default {
  // Menu
  badge: 'Test',
  title: 'Test Session',
  description: '[Session description]',
  test: true,

  // Route parameter & Jackrabbit identifier
  name: 'te$t',

  // Disciplines & class types
  disciplines: [
    {
      ...GYMNASTICS,
      classTypes: TEST_CLASSES,
    },
    {
      ...CIRCUS,
      classTypes: TEST_CLASSES,
    },
    {
      ...ACRO,
      classTypes: TEST_CLASSES,
    },
    {
      ...NINJA,
      classTypes: TEST_CLASSES,
    },
  ],

  // Registration date announcement & session details
  announcement: '[Session registration date announcement]',
  details: '[Session details]',
}
