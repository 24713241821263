export default {
  // Menu
  title: 'March Break Camp',
  badge: null,
  description: 'Runs over the school break',

  // Route parameter & Jackrabbit identifiers
  name: 'march-break',
  cat1: 'Camp',
  cat3s: [
    'March Break Camp schedule coming soon',
    // 'Week 1'
  ],

  // Hero section
  hero: {
    title: 'March Break Camp',
    subtitle: 'Girls & Boys | Ages 4+',
    image: require('@/assets/hero-camp.jpg'),
    position: 'center',
  },

  // Welcome section
  welcome: {
    title: 'March Break Camp details coming soon',
    text: 'Whitby Gym & Circus proudly offers March Break Camp for kids. Our camp offers small groups doing focused activities. Campers spend the day with children their own age doing their chosen activities. Our camps prioritize safety, positive experience, and skill advancement.',
    buttonText: 'See camp availability',
    identifier: '#available-camps',
  },

  // Whether to additionally display segregated camp info
  segregated: false,
}
