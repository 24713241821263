import { ACRO_FOR_DANCERS, ADULT_GYM } from '@/assets/classTypes/acro'
import { ADULT_CIRCUS, JR_CIRCUS, SR_CIRCUS, TEEN_CIRCUS } from '@/assets/classTypes/circus'
import {
  JR_BOYS_GYM,
  JR_GIRLS_GYM,
  JR_KINDERGYM,
  PARENT_AND_TOT,
  SR_GIRLS_GYM,
  SR_KINDERGYM,
  TEEN_GYM,
  TOTS_KINDERGYM,
} from '@/assets/classTypes/gymnastics'
import { JR_NINJA, SR_NINJA } from '@/assets/classTypes/ninja'

import ACRO from '@/assets/disciplines/acro'
import CIRCUS from '@/assets/disciplines/circus'
import GYMNASTICS from '@/assets/disciplines/gymnastics'
import NINJA from '@/assets/disciplines/ninja'

export default {
  // Menu
  title: 'Spring Session',
  badge: null,
  description: 'Runs April–June',
  disabled: true,

  // Route parameter & Jackrabbit identifier
  name: 'spring',
  id: '2024 Spring', // Session identifier in Jackrabbit, e.g. Spring 2023

  // Disciplines & classes
  disciplines: [
    {
      ...GYMNASTICS,
      classTypes: [
        PARENT_AND_TOT,
        TOTS_KINDERGYM,
        JR_KINDERGYM,
        SR_KINDERGYM,
        JR_BOYS_GYM,
        JR_GIRLS_GYM,
        SR_GIRLS_GYM,
        TEEN_GYM,
      ],
    },
    { ...CIRCUS, classTypes: [JR_CIRCUS, SR_CIRCUS, TEEN_CIRCUS, ADULT_CIRCUS] },
    {
      ...ACRO,
      classTypes: [ACRO_FOR_DANCERS, ADULT_GYM],
    },
    { ...NINJA, classTypes: [JR_NINJA, SR_NINJA] },
  ],

  // Registration date announcement & session details
  announcement: 'Registration open now',
  details: `This session runs on the following dates:

  • Saturday classes: April 6th–June 22nd (10 weeks)
  • Sunday classes: April 7th–June 23rd (10 weeks)
  • Monday classes: April 8th–June 24th (10 weeks)
  • Tuesday classes: April 9th–June 18th (11 weeks)
  • Wednesday classes: April 10th–June 19th (10 weeks)
  • Thursday classes: April 11th–June 20th (10 weeks)
  
  Classes will not run:
  • Friday May 17th–Monday May 20th (May Long Weekend)
  • Wednesday May 22nd–Monday May 27th (Competition)

  Each participant must have valid Gymnastics Ontario insurance. A non-refundable insurance fee of $46 + HST applies to each as-of-yet uninsured participant, and is valid until next July.`,
}
