export const TEST_CLASS_1 = {
  title: 'Test Class 1',
  gender: 'Robots only',
  ages: 'All ages',
  description: '',
  cat1: 'TestCat1.1',
}

export const TEST_CLASS_2 = {
  title: 'Test Class 2',
  gender: 'Robots only',
  ages: 'All ages',
  description: '',
  cat1: 'TestCat1.2',
}

export const TEST_CLASS_3 = {
  title: 'Test Class 3',
  gender: 'Robots only',
  ages: 'All ages',
  description: '',
  cat1: 'TestCat1.3',
}
