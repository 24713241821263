<template>
  <section class="py-12">
    <v-container>
      <p class="text-h4 font-fancy" style="white-space: pre-line">{{ title }}</p>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="mt-6" v-html="text" />
      <v-btn v-if="buttonText" class="mt-6" large color="secondary" rounded @click="$vuetify.goTo(identifier)">
        {{ buttonText }}
      </v-btn>
    </v-container>
  </section>
</template>

<script>
export default {
  props: {
    title: String,
    text: String,
    buttonText: String,
    identifier: String,
  },
}
</script>

<style scoped>
section {
  background-color: white;
  background-image: url('../assets/grey-dots.jpg');
}
</style>
